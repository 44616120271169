import {
  Flex,
  Text,
  Select,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useState } from 'react';
import GsBtn from '../../../../components/button/GsBtn';
import GsHeaderPopOver from '../../../../components/heading/GsHeaderPopOver';
import { WorkerBySectorDTO } from '../../worker-api';

type TaskAssignmentProps = {
  button: ReactNode;
  taskId: number;
  workers: WorkerBySectorDTO[];
  isOpen: boolean;
  isTaskStarted: boolean;
  onClose: () => void;
  currentWorker: WorkerBySectorDTO;
  onSave: (worker: WorkerBySectorDTO, showAlert: boolean) => void;
};

const TaskAssignmentPopOver: FC<TaskAssignmentProps> = ({
  button,
  isOpen,
  isTaskStarted,
  onClose,
  workers,
  currentWorker,
  onSave,
}) => {
  const [selectedWorker, setSelectedWorker] = useState(currentWorker);
  const [assignedWorker, setAssignedWorker] = useState('');

  useEffect(() => {
    if (isOpen && currentWorker) setAssignedWorker(currentWorker.workerName);
  }, [isOpen]);

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement='left' trigger='hover'>
      <PopoverTrigger>{button}</PopoverTrigger>
      <PopoverContent>
        <GsHeaderPopOver title='Izvođač radova' />
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Flex
            direction='column'
            gap='5%'
            alignItems='flex-start'
            justifyContent='space-between'
          >
            {assignedWorker && (
              <Text whiteSpace='nowrap'>Izabran za rad: {assignedWorker}</Text>
            )}
            <Select
              placeholder='Izaberite novog'
              onChange={(e) => {
                const newWorkerId = Number(e.target.value);
                const newWorker = workers.find(
                  (worker) => worker.userId === newWorkerId
                );
                setSelectedWorker(newWorker as WorkerBySectorDTO);
              }}
            >
              {workers.map((worker) => (
                <option key={worker.userId} value={worker.userId}>
                  {worker.workerName}
                </option>
              ))}
            </Select>
          </Flex>
        </PopoverBody>
        <PopoverFooter display='flex' justifyContent='flex-end'>
          <GsBtn
            text='Sačuvaj'
            onClick={() => {
              const shouldChangeWorker = assignedWorker
                ? selectedWorker?.workerName !== assignedWorker
                : true;
              if (shouldChangeWorker || !isTaskStarted)
                onSave(selectedWorker as WorkerBySectorDTO, true);
              onClose();
            }}
          />
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default TaskAssignmentPopOver;
