import { EditIcon, DeleteIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Tr, Td } from '@chakra-ui/react';
import { useState } from 'react';
import GsIconBtn from '../../../../components/button/GsIconBtn';
import {
  ALERT_GREEN,
  useGsDialog,
} from '../../../../contexts/GsConfirmDialogContext';
import { Resource } from '../../resource/resource-api';
import MissingResourcesModal from '../modal/missingResourcesModal';
import {
  OrderTableProps,
  convertDate,
  deleteOrder,
  getOrderItems,
  convertPriority,
  convertOrderStatus,
  updateOrderStatus,
  getOrders,
  Order,
} from '../order-apis';

function OrderTableRow({
  order,
  setOrder,
  setOrders,
  setOrderToEdit,
  setOrderItems,
  onOpen,
}: OrderTableProps) {
  const [isMissingResourceModalOpen, setIsMissingResourceModalOpen] =
    useState(false);
  const [missingResourcesState, setMissingResoucesState] = useState<Resource[]>(
    []
  );
  const { confirmDialog, alertDialog } = useGsDialog();

  const handleDisablingOrder = async () => {
    confirmDialog(
      'Deaktivacija porudžbine',
      'Da li sigurno želite da deaktivirate porudžbinu?',
      async () => {
        if (order === undefined) return;
        const missingResources = await updateOrderStatus(order, 'deactivate');
        if (missingResources !== undefined) {
          const message = `Porudžbina "${order?.orderCode}" je uspešno deaktivirana, radnici neće moći da je vide`;
          alertDialog('', message, ALERT_GREEN);
          getOrders(setOrders, 0);
        }
      }
    );
  };

  const handleEnablingOrder = async () => {
    confirmDialog(
      'Aktivacija porudžbine',
      'Da li sigurno želite da aktivirate porudžbinu?',
      async () => {
        if (order === undefined) return;
        const missingResources = await updateOrderStatus(order, 'activate');
        if (missingResources !== undefined) {
          const message = `Porudžbina "${order?.orderCode}" je uspešno puštena u proizvodnju`;
          alertDialog('', message, ALERT_GREEN);
          if (missingResources?.length > 0) {
            setIsMissingResourceModalOpen(true);
            setMissingResoucesState(missingResources);
          } else {
            getOrders(setOrders, 0);
          }
        }
      }
    );
  };

  const handleDeletingOrder = async (order: Order) => {
    confirmDialog(
      'Brisanje porudžbine',
      'Da li sigurno želite da obrišete porudžbinu ?',
      () => {
        deleteOrder(order, setOrders).then((sucess) => {
          if (sucess) {
            alertDialog('', 'Porudžbina je obrisana', ALERT_GREEN);
            getOrders(setOrders, 0);
          }
        });
      }
    );
  };

  return (
    <Tr>
      <Td textAlign='center' w='16%'>
        {order?.orderCode}
      </Td>
      <Td textAlign='center' w='12%'>
        {order?.companyName}
      </Td>
      <Td textAlign='center' w='10%'>
        {convertDate(order?.estimatedStart)}
      </Td>
      <Td textAlign='center' w='10%'>
        {convertDate(order?.realEnd)}
      </Td>
      <Td textAlign='center' w='10%'>
        {' '}
        {convertPriority(order?.priority)}
      </Td>
      <Td textAlign='center' w='8%'>
        {order?.active ? (
          <GsIconBtn
            label='Deactivate Order'
            icon={<CloseIcon />}
            disabled={
              order?.status === 'COMPLETE' ||
              order?.status === 'MANUALLY COMPLETE'
            }
            onClick={handleDisablingOrder}
          />
        ) : (
          <GsIconBtn
            label='Activate Order'
            icon={<CheckIcon />}
            disabled={
              order?.status === 'COMPLETE' ||
              order?.status === 'MANUALLY COMPLETE'
            }
            onClick={handleEnablingOrder}
          />
        )}
      </Td>
      <Td textAlign='center' w='8%'>
        <GsIconBtn
          label='edit order'
          icon={<EditIcon />}
          onClick={async () => {
            if (order !== undefined) {
              setOrderToEdit(order);
              setOrder(true);
              onOpen();
              if (order.id) {
                let orderItems = await getOrderItems(order.id);
                setOrderItems(orderItems);
              }
            }
          }}
        />
      </Td>
      <Td textAlign='center' w='8%'>
        <GsIconBtn
          label='delete order'
          icon={<DeleteIcon />}
          onClick={() => handleDeletingOrder(order as Order)}
        />
      </Td>
      <MissingResourcesModal
        isOpen={isMissingResourceModalOpen}
        onClose={() => {
          setIsMissingResourceModalOpen(false);
          window.location.reload();
        }}
        resources={missingResourcesState}
        orderCode={order?.orderCode as string}
      ></MissingResourcesModal>
      <Td textAlign='center' w='12%'>
        {convertOrderStatus(order?.status)}
      </Td>
    </Tr>
  );
}

export default OrderTableRow;
