import { Box, Flex, Heading, useChakra } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppLayout from '../../components/layout/app-layout';
import WorkerTable from '../worker/components/worker-table';
import { TxtClr } from '../../components/theme/custom-theme';
import {
  getSectorWorkers,
  getWorkerData,
  TableDataDTO,
  WorkerBySectorDTO,
} from './worker-api';
import { TableNavigation } from '../../components/TabeNavigation/TableNavigation';
import WorkerSearch from './components/workerSearch';
import GsSpinner from '../../components/spinner/gsSpinner';
import { User } from '../login/login-api';

const WorkerPage = () => {
  const url = useLocation().pathname.split('/');
  const sectorId = url.at(url.length - 1) as string;
  const { theme, colorMode } = useChakra();
  const [tableData, setTableData] = useState<TableDataDTO['workerPageDTO']>([]);
  const [sectorName, setSectorName] = useState('');
  const [defaultWorker, setDefaultWorker] = useState<WorkerBySectorDTO>();
  const [sectorMinWorkTime, setSectorMinWorkTime] = useState(0);
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const fiveMinsRefresh = 30 * 60 * 1000;
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedOption, setSelectedOption] = useState('Order');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAnyModalOpen, setIsAnyModalOpen] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const currentUser: User = JSON.parse(localStorage.getItem('user') as string);
  const [workers, setWorkers] = useState<WorkerBySectorDTO[]>([]);

  const callGetTableData = async (
    orderCode: string,
    productCode: string,
    instructions: string,
    isChecked: boolean
  ) => {
    setIsLoading(true);
    setWorkers(await getSectorWorkers(Number(sectorId)));
    const response = await getWorkerData(
      Number(sectorId),
      pageNumber,
      orderCode,
      productCode,
      currentUser.userId,
      instructions,
      isChecked
    );

    setSectorName(response.sectorName);
    setSectorMinWorkTime(response.sectorMinWorkTime);
    setDefaultWorker(response.defaultWorker);
    setTableData(response.workerPageDTO);
    setIsLoading(false);
  };

  const handleInitalAndRefreshCalls = async (updatedIsChecked: boolean) => {
    let searchValue = searchRef.current?.value;
    if (searchValue == '') callGetTableData('', '', '', updatedIsChecked);
    else if (selectedOption == 'Order' && searchValue !== undefined)
      callGetTableData(searchValue, '', '', updatedIsChecked);
    else if (selectedOption == 'Product' && searchValue !== undefined)
      callGetTableData('', searchValue, '', updatedIsChecked);
    else if (selectedOption == 'Instructions' && searchValue !== undefined)
      callGetTableData('', '', searchValue, updatedIsChecked);
  };

  useEffect(() => {
    if (pageNumber === 0) setTableDataChanged(!tableDataChanged);
    else setPageNumber(0);
  }, [sectorId]);

  useEffect(() => {
    handleInitalAndRefreshCalls(isChecked);
    const refreshInterval = setInterval(() => {
      console.log('refreshing');
      handleInitalAndRefreshCalls(isChecked);
    }, fiveMinsRefresh);

    return () => {
      setSectorName('');
      setSectorMinWorkTime(0);
      setTableData([]);
      clearInterval(refreshInterval);
    };
  }, [tableDataChanged, pageNumber]);

  const onTaskFinished = () => {
    setTableDataChanged(!tableDataChanged);
  };

  const pageNumberHandler = (num: number) => {
    if (num === 1) setPageNumber((prevPageNumber) => prevPageNumber + 1);
    else if (num === -1)
      setPageNumber((prevPageNumber) =>
        prevPageNumber > 0 ? prevPageNumber - 1 : 0
      );
    else {
      if (pageNumber === 0) setTableDataChanged(!tableDataChanged);
      else setPageNumber(0);
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  return (
    <Flex flexDir='column' w='100%'>
      <Box
        flexDirection='row'
        width='full'
        bg={theme.colors.bg.colorMode(colorMode)}
        borderRadius='10'
        borderWidth={3}
        p='20'
      >
        <Heading as='h2' mb='5' pb='10' color={TxtClr()} size='2xl'>
          {sectorName}
        </Heading>

        <WorkerSearch
          setSelectedOption={setSelectedOption}
          pageNumberHandler={pageNumberHandler}
          searchRef={searchRef}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          handleInitalAndRefreshCalls={handleInitalAndRefreshCalls}
          sectorId={sectorId}
          selectedOption={selectedOption}
        ></WorkerSearch>

        <Box>
          {isLoading ? (
            <GsSpinner></GsSpinner>
          ) : (
            <WorkerTable
              data={tableData}
              workers={workers}
              sectorMinWorkTime={sectorMinWorkTime}
              onTaskFinishedHandler={onTaskFinished}
              handleInitalAndRefreshCalls={handleInitalAndRefreshCalls}
              isChecked={isChecked}
              setIsAnyModalOpen={setIsAnyModalOpen}
              isAnyModalOpen={isAnyModalOpen}
              defaultWorker={defaultWorker}
            />
          )}
        </Box>

        <Box marginTop={10}>
          <TableNavigation
            pageNumberHandler={pageNumberHandler}
            pageNumber={pageNumber}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default WorkerPage;
