import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Tr, Td, IconButton } from '@chakra-ui/react';
import { FC } from 'react';
import {
  User,
  convertRole,
  deleteUser,
  getUsers,
  isCurrentUser,
} from '../../user-api';
import { RiEdit2Line } from 'react-icons/ri';

const UserTableRow: FC<{
  user: User;
  onUserSelect: (user: User) => void;
  onUserSelectforPW: (user: User) => void;
}> = ({ user, onUserSelect, onUserSelectforPW }) => {
  const role = user.roleName ? convertRole(user.roleName) : '';
  return (
    <Tr>
      <Td alignItems='center' justifyContent='center'>
        {user.name}
      </Td>
      <Td alignItems='center' justifyContent='center'>
        {user.username}
      </Td>
      <Td alignItems='center' justifyContent='center'>
        {role}
      </Td>
      <Td alignItems='center' justifyContent='center'>
        <IconButton
          aria-label='edit-icon'
          marginLeft='25'
          icon={<EditIcon />}
          onClick={() => onUserSelect(user)}
        />
      </Td>
      <Td alignItems='center' justifyContent='center'>
        <IconButton
          aria-label='edit-pw-icon'
          marginLeft='25'
          icon={<RiEdit2Line />}
          onClick={() => onUserSelectforPW(user)}
        />
      </Td>
      <Td alignItems='center' justifyContent='center'>
        <IconButton
          aria-label='delete-icon'
          marginLeft='25'
          icon={<DeleteIcon />}
          onClick={async (e) => {
            if (
              window.confirm(
                'Da li ste sigurni da želite da obrišete korisnika?'
              )
            ) {
              if (user === undefined) return;
              if (isCurrentUser(user)) {
                alert('Ne mozete obrisati korisnika sa kojim ste loginovani');
                return;
              }
              const sucess = await deleteUser(user);

              if (sucess === true) {
                alert('Korisnik je obrisan');
                window.location.reload();
              } else {
                alert('Greska pri brisanju korisnika');
                window.location.reload();
              }
            }
          }}
        />
      </Td>
    </Tr>
  );
};

export default UserTableRow;
