import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TableContainer,
  useDisclosure,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Select,
  Box,
  Icon,
  InputLeftElement,
} from '@chakra-ui/react';
import { useChakra } from '@chakra-ui/react';
import { FormControl, FormLabel, FormHelperText } from '@chakra-ui/react';
import { DeleteIcon, SearchIcon, AddIcon } from '@chakra-ui/icons';
import React, { useEffect } from 'react';
import {
  Product,
  OrderItem,
  createOrder,
  Order,
  getOrders,
  ProductTableProps,
  OrderFetch,
} from './order-apis';
import { getProducts, getProductsByFilter } from '../product/product-api';
import { useState, useRef } from 'react';
import AppLayout from '../../../components/layout/app-layout';
import { TableNavigation } from '../../../components/TabeNavigation/TableNavigation';
import { CustomHeading } from '../../../components/heading/custom-heading';
import GsSpinner from '../../../components/spinner/gsSpinner';
import OrderTable from './components/orderTable';
import OrderItemsTable from './components/orderItemTable';
import { checkTwoSpaces } from '../../../utilility/util';
import { Resource } from '../resource/resource-api';
import MissingResourcesModal from './modal/missingResourcesModal';
import { FaSearch } from 'react-icons/fa';

const EditOrderPage = () => {
  const { theme, colorMode } = useChakra();

  return (
    <AppLayout>
      <Flex flexDir='column' w='100%'>
        <Flex
          flexDirection='column'
          w='100%'
          height='100%'
          mb={1}
          p='20'
          bg={theme.colors.bg.colorMode(colorMode)}
          borderRadius='10'
          borderWidth={3}
        >
          <CustomHeading text='Pregled porudžbine' />
          <CreateOrderForm />
        </Flex>
      </Flex>
    </AppLayout>
  );
};

const CreateOrderForm = () => {
  const [orders, setOrders] = useState<OrderFetch[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [addedProducts, setAddedProducts] = useState<Product[]>([]);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [orderToEdit, setOrderToEdit] = useState<OrderFetch>();
  const [isOrderSetted, setOrder] = useState<Boolean>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [productPageNo, setProductPageNo] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const orderCodeToSearch = useRef<HTMLInputElement>(null);
  const orderCodeRef = useRef<HTMLInputElement>(null);
  const companyNameRef = useRef<HTMLInputElement>(null);
  const priorityOrderRef = useRef<HTMLSelectElement>(null);
  const estimatedStartRef = useRef<HTMLInputElement>(null);
  const estimatedEndRef = useRef<HTMLInputElement>(null);
  const realEndRef = useRef<HTMLInputElement>(null);
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [missingResourcesState, setMissingResoucesState] = useState<Resource[]>(
    []
  );
  const [isMissingResourceModalOpen, setIsMissingResourceModalOpen] =
    useState(false);
  const [orderCode, setOrderCode] = useState<string>('');
  const [isActive, setIsActive] = useState('');
  const [orderStatusValue, setOrderStatusValue] = useState('');
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  useEffect(() => {
    callGetOrders();
  }, [pageNumber, isActive, orderStatusValue]);

  const callGetOrders = () => {
    let isOrderActiveFilter;
    if (isActive === 'active') isOrderActiveFilter = true;
    else if (isActive === 'inactive') isOrderActiveFilter = false;
    else isOrderActiveFilter = undefined;

    const filter = orderCodeToSearch.current
      ? orderCodeToSearch.current.value
      : '';

    getOrders(
      setOrders,
      pageNumber,
      filter,
      isOrderActiveFilter,
      orderStatusValue
    );
  };

  const handleOnClose = async () => {
    setMissingResoucesState([]);
    onClose();
  };

  const onIsActiveChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setIsActive(event.target.value as string);
    setPageNumber(0);
  };

  const onOrderStatusValueChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setOrderStatusValue(event.target.value as string);
    setPageNumber(0);
  };

  const onEditOrder = async () => {
    if (!validateOrder()) return;
    const estStart: string = estimatedStartRef.current?.value as string;
    const estDate: string = estimatedEndRef.current?.value as string;
    const realEndDate: string = realEndRef.current?.value as string;
    const orderCode: string = orderCodeRef.current?.value as string;
    const companyName: string = companyNameRef.current?.value as string;
    let active = orderToEdit?.active;

    let missingResources: Resource[] | undefined = await createOrder(
      {
        id: orderToEdit?.id,
        orderCode: orderCode,
        companyName: companyName,
        items: orderItems,
        estimatedStart: estStart,
        estimatedEnd: estDate,
        realEnd: realEndDate,
        priority: priorityOrderRef.current?.value,
        active: active,
      } as Order,
      setIsLoading
    );
    setIsLoading(false);
    setOrderCode(orderCode);

    alert('Porudzbina sacuvana uspešno');

    if (missingResources !== undefined) {
      if (missingResources.length > 0 && active) {
        setIsMissingResourceModalOpen(true);
        setMissingResoucesState(missingResources);
        callGetOrders();
      } else {
        clearForm();
        callGetOrders();
        onClose();
      }
    }
  };

  const validateOrder = () => {
    if (
      orderCodeRef.current === null ||
      companyNameRef.current === null ||
      estimatedEndRef.current === null ||
      estimatedStartRef.current === null ||
      realEndRef.current === null ||
      priorityOrderRef === null
    ) {
      console.log('Undefined');
      return false;
    }

    if (orderCodeRef.current?.value === '') {
      alert('Unesite šifru porudzbenice');
      return false;
    }

    if (checkTwoSpaces(orderCodeRef.current.value)) {
      alert('Nisu dozvoljena dva uzastopna znaka razmaka kod šifre proizvoda');
      return false;
    }

    if (companyNameRef.current?.value === '') {
      alert('Unesite ime kompanije');
      return false;
    }

    if (estimatedStartRef.current?.value === '') {
      alert('Unesite očekivano vreme završetka');
      return false;
    }

    if (estimatedEndRef.current?.value === '') {
      alert('Unesite očekivano vreme završetka');
      return false;
    }

    if (realEndRef.current?.value === '') {
      alert('Unesite stvarno vreme isporuke porudzbenice');
      return false;
    }

    if (priorityOrderRef.current?.value === '') {
      alert('Izaberite prioritet');
      return false;
    }

    return true;
  };

  const clearForm = async () => {
    setOrderItems([]);
    setOrderToEdit({
      companyName: '',
      orderCode: '',
      items: [],
      dateCreated: '',
      estimatedStart: '',
      estimatedEnd: '',
      realEnd: '',
      priority: '',
    } as OrderFetch);
    if (orderCodeRef.current != null) {
      orderCodeRef.current.value = '';
    }
    if (companyNameRef.current != null) {
      companyNameRef.current.value = '';
    }
    if (estimatedStartRef.current != null) {
      estimatedStartRef.current.value = '';
    }
    if (estimatedEndRef.current != null) {
      estimatedEndRef.current.value = '';
    }
    if (realEndRef.current != null) {
      realEndRef.current.value = '';
    }
    if (priorityOrderRef.current != null) {
      priorityOrderRef.current.value = '';
    }
  };

  const renderCode = isOrderSetted ? (
    <div>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleOnClose}
      >
        <ModalOverlay />
        <ModalContent minWidth='1100px'>
          <ModalHeader>Izmena porudžbine</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <>
              <Flex flexDirection='row' mb='50' align={'center'}>
                <FormControl mr='50' w='30%'>
                  <FormLabel>Šifra porudžbine</FormLabel>
                  <div key={orderToEdit?.orderCode}>
                    <Input
                      defaultValue={orderToEdit?.orderCode}
                      type='text'
                      ref={orderCodeRef}
                    />
                  </div>
                  <FormHelperText fontStyle='italic'>
                    Unesite šifru nove porudžbine
                  </FormHelperText>
                </FormControl>
                <FormControl mr='50' w='30%'>
                  <div key={orderToEdit?.companyName}>
                    <FormLabel>Naziv kompanije</FormLabel>
                    <Input
                      defaultValue={orderToEdit?.companyName}
                      type='text'
                      ref={companyNameRef}
                    />
                  </div>
                  <FormHelperText fontStyle='italic'>
                    Unesite naziv kompanije koja je izdala porudžbinu
                  </FormHelperText>
                </FormControl>
                <FormControl w='30%'>
                  <div key={orderToEdit?.priority}>
                    <FormLabel>Prioritet</FormLabel>
                    <Select
                      placeholder='Prioritet'
                      defaultValue={
                        orderToEdit?.priority ? orderToEdit?.priority : ''
                      }
                      ref={priorityOrderRef}
                    >
                      <option key={1} value='LOW'>
                        Nizak
                      </option>
                      <option key={2} value='MEDIUM'>
                        Srednji
                      </option>
                      <option key={2} value='HIGH'>
                        Visok
                      </option>
                    </Select>
                  </div>
                  <FormHelperText fontStyle='italic'>
                    Izaberite prioritet
                  </FormHelperText>
                </FormControl>
              </Flex>
              <Flex flexDirection='row' mb='50' align={'center'}>
                <FormControl mr='50' w='30%'>
                  <FormLabel>Datum prijema</FormLabel>
                  <Input
                    placeholder=''
                    type='date'
                    ref={estimatedStartRef}
                    defaultValue={orderToEdit?.estimatedStart.split('T', 1)}
                  />
                </FormControl>
                <FormControl mr='50' w='30%'>
                  <FormLabel>Željeni datum isporuke</FormLabel>
                  <Input
                    placeholder=''
                    type='date'
                    ref={estimatedEndRef}
                    defaultValue={orderToEdit?.estimatedEnd.split('T', 1)}
                  />
                </FormControl>
                <FormControl w='30%'>
                  <FormLabel>Stvarni datum isporuke</FormLabel>
                  <Input
                    placeholder=''
                    type='date'
                    ref={realEndRef}
                    defaultValue={
                      orderToEdit?.realEnd
                        ? orderToEdit?.realEnd.split('T', 1)
                        : ''
                    }
                  />
                </FormControl>
              </Flex>
              <Flex flexDirection='row' mb='50' align={'center'}>
                <FormControl mr='50' w='30%'>
                  <FormLabel>Datum kreiranja</FormLabel>
                  <Input
                    placeholder=''
                    type='date'
                    defaultValue={orderToEdit?.dateCreated.split('T', 1)}
                    disabled={true}
                  />
                </FormControl>
                <FormControl mr='50' w='30%'>
                  <FormLabel>Status</FormLabel>
                  <Input
                    placeholder=''
                    type='text'
                    defaultValue={orderToEdit?.active ? 'Aktivan' : 'Neaktivan'}
                    disabled={true}
                  />
                </FormControl>
              </Flex>
              <OrderItemModal
                products={products}
                setProducts={setProducts}
                setAddedProducts={setAddedProducts}
                addedProducts={addedProducts}
                setOrderItems={setOrderItems}
                orderItems={orderItems}
                pageNumber={productPageNo}
                setPageNumber={setProductPageNo}
              />
              <OrderItemsTable
                orderItems={orderItems}
                setOrderItems={setOrderItems}
                orderToEdit={orderToEdit as Order}
              />
              {isLoading ? (
                <GsSpinner />
              ) : (
                <Button
                  w='100%'
                  marginTop='10px'
                  marginBottom='50px'
                  textAlign='center'
                  _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
                  variant='outline'
                  onClick={async (e) => {
                    await onEditOrder();
                  }}
                >
                  Izmeni porudžbinu
                </Button>
              )}
            </>
            <MissingResourcesModal
              isOpen={isMissingResourceModalOpen}
              onClose={() => setIsMissingResourceModalOpen(false)}
              resources={missingResourcesState}
              orderCode={orderCode}
            ></MissingResourcesModal>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  ) : null;

  return (
    <Box px='5'>
      <Flex direction='row'>
        <Box w='30%' mb='20px'>
          <FormControl>
            <FormLabel>Pretražite porudžbenicu</FormLabel>
            <InputGroup alignItems='center'>
              <InputLeftElement
                pointerEvents='none'
                children={
                  <Icon
                    as={FaSearch}
                    color='gray.500'
                    fontSize='20px'
                    marginTop='5px'
                  />
                }
              />
              <Input
                placeholder='Šifra porudžbine'
                type='text'
                ref={orderCodeToSearch}
                onChange={() => {
                  if (pageNumber !== 0) setPageNumber(0);
                  else callGetOrders();
                }}
              />
            </InputGroup>
            <FormHelperText fontStyle='italic'>
              Pretražite porudžbenicu po ID-u
            </FormHelperText>
          </FormControl>
        </Box>
        <Box ml='35%' w='20%'>
          <FormLabel> Aktivacija </FormLabel>
          <Select value={isActive} onChange={onIsActiveChange}>
            <option value=''>Sve</option>
            <option value='active'>Aktivne</option>
            <option value='inactive'>Neaktivne</option>
          </Select>
        </Box>
        <Box ml='5%' w='20%'>
          <FormLabel> Status </FormLabel>
          <Select value={orderStatusValue} onChange={onOrderStatusValueChange}>
            <option value=''>Sve</option>
            <option value='IN_PROGRESS'>U izradi</option>
            <option value='COMPLETE'>Završene</option>
          </Select>
        </Box>
      </Flex>
      {isLoadingOrders ? (
        <GsSpinner />
      ) : (
        <OrderTable
          orders={orders}
          setOrders={setOrders}
          setOrderToEdit={setOrderToEdit}
          setOrderItems={setOrderItems}
          setOrder={setOrder}
          fieldRef={orderCodeToSearch}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          onOpen={onOpen}
          isLoadingOrders={isLoadingOrders}
          setOrdersLoading={setLoadingOrders}
        ></OrderTable>
      )}
      {renderCode}{' '}
      {/* Ovaj kod se prikazuje samo kada se izabere order za editovanje */}
    </Box>
  );
};

function ProductTable({
  products,
  setProducts,
  addedProducts,
  setAddedProducts,
  setOrderItems,
  orderItems,
  pageNumber,
  setPageNumber,
  fieldRef,
}: ProductTableProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    let searchValue = fieldRef.current.value;
    if (!searchValue || searchValue.trim().length === 0)
      searchValue = 'mostPopular';
    getProductsByFilter(searchValue, setProducts, newPageNumber, setIsLoading);
    setPageNumber(newPageNumber);
  };

  return (
    <TableContainer marginTop='30px' maxWidth='1200px'>
      <Table variant='simple'>
        <TableCaption>Proizvodi</TableCaption>
        <Thead>
          <Tr>
            <Th>Šifra proizvoda</Th>
            <Th>Naziv Proizvoda</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {addedProducts?.map((p) => (
            <ProductTableRow
              key={p.id}
              product={p}
              addedProducts={addedProducts}
              setProducts={setProducts}
              setAddedProducts={setAddedProducts}
              products={[]}
              isAdded={true}
              setOrderItems={setOrderItems}
              orderItems={orderItems}
              pageNumber={pageNumber}
              fieldRef={fieldRef}
              setPageNumber={setPageNumber}
            />
          ))}
          {products.map((p) => (
            <ProductTableRow
              key={p.id}
              product={p}
              setProducts={setProducts}
              addedProducts={addedProducts}
              setAddedProducts={setAddedProducts}
              products={[]}
              isAdded={false}
              setOrderItems={setOrderItems}
              orderItems={orderItems}
              pageNumber={pageNumber}
              fieldRef={fieldRef}
              setPageNumber={setPageNumber}
            />
          ))}
        </Tbody>
      </Table>
      <TableNavigation
        pageNumber={pageNumber}
        pageNumberHandler={pageNumberHandler}
      />
    </TableContainer>
  );
}

function ProductTableRow({
  products,
  setProducts,
  product,
  addedProducts,
  isAdded,
  setAddedProducts,
}: ProductTableProps) {
  return (
    <Tr>
      <Td>{product?.productCode}</Td>
      <Td>{product?.description}</Td>
      <Td>
        <IconButton
          aria-label='theme'
          alignItems='center'
          icon={isAdded ? <DeleteIcon /> : <AddIcon />}
          onClick={(e) => {
            if (isAdded) {
              if (
                window.confirm(
                  'Da li ste sigurni da želite da obrišete proizvod iz tabele?'
                )
              ) {
                let newAdded = addedProducts?.filter(
                  (p) => p.productCode !== product?.productCode
                );
                setAddedProducts(newAdded);
              }
            } else {
              setAddedProducts([...addedProducts, product]);
              let newProducts = products?.filter(
                (p) => p.productCode !== product?.productCode
              );
              setProducts(newProducts);
            }
          }}
        />
      </Td>
    </Tr>
  );
}

function OrderItemModal({
  products,
  setProducts,
  addedProducts,
  setAddedProducts,
  setOrderItems,
  orderItems,
  pageNumber,
  setPageNumber,
}: ProductTableProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [orderId, setOrderId] = useState<string>('');
  const [amount, setAmount] = useState<number>(0.0);
  const [inputProduct, setInputProduct] = useState<string>('');
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const productNameRef = useRef<HTMLInputElement>(null);
  const priorityRef = useRef<HTMLSelectElement>(null);
  const itemEstimatedEndRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      getProductsByFilter('mostPopular', setProducts, 0, setIsLoading);
      setInputProduct('mostPopular');
      setPageNumber(0);
    }
  }, [isOpen, setProducts, setIsLoading, setPageNumber]);

  return (
    <Flex w='100%'>
      <Button
        onClick={onOpen}
        w='48%'
        mb='50'
        textAlign='center'
        _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
        variant='outline'
      >
        Dodaj stavku
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent minWidth='800px'>
          <ModalHeader>Dodavanje stavke porudžbine</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mt={4}>
              <FormLabel>Proizvod</FormLabel>
              <InputGroup>
                <InputRightElement
                  children={
                    <IconButton
                      aria-label='theme'
                      icon={<SearchIcon />}
                      onClick={(e) => {
                        let searchValue = inputProduct;
                        if (!searchValue || searchValue.trim().length === 0)
                          searchValue = 'mostPopular';
                        getProductsByFilter(
                          searchValue,
                          setProducts,
                          0,
                          setIsLoading
                        );
                        setPageNumber(0);
                      }}
                    ></IconButton>
                  }
                />
                <Input
                  placeholder='Pretražite proizvod'
                  ref={productNameRef}
                  onChange={(e) => {
                    let searchValue = e.target.value;
                    if (!searchValue || searchValue.trim().length === 0)
                      searchValue = 'mostPopular';
                    getProductsByFilter(
                      searchValue,
                      setProducts,
                      0,
                      setIsLoading
                    );
                    setInputProduct(searchValue);
                    setPageNumber(0);
                  }}
                />
              </InputGroup>
            </FormControl>

            {isLoading ? (
              <GsSpinner />
            ) : (
              <ProductTable
                products={products}
                setProducts={setProducts}
                setAddedProducts={setAddedProducts}
                addedProducts={addedProducts}
                setOrderItems={setOrderItems}
                orderItems={orderItems}
                pageNumber={pageNumber}
                fieldRef={productNameRef}
                setPageNumber={setPageNumber}
              ></ProductTable>
            )}

            <FormControl mt={4}>
              <FormLabel>
                Količina ( {addedProducts[0]?.measureUnit?.unitName} )
              </FormLabel>
              <Input
                placeholder='Količina'
                onChange={(e) => {
                  if (!e.target.value || e.target.value.length === 0) return;
                  setAmount(Number(e.target.value));
                }}
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Datum isporuke stavke (opciono)</FormLabel>
              <Input
                placeholder='Datum isporuke stavke'
                type='date'
                ref={itemEstimatedEndRef}
              ></Input>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Izaberite prioritet (opciono)</FormLabel>
              <Select placeholder='Prioritet' ref={priorityRef}>
                <option key={1} value='LOW'>
                  Nizak
                </option>
                <option key={2} value='MEDIUM'>
                  Srednji
                </option>
                <option key={2} value='HIGH'>
                  Visok
                </option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
              variant='outline'
              onClick={(e) => {
                if (amount === -1 || isNaN(amount)) {
                  alert('Unesite broj za kolicinu');
                  return;
                }
                if (amount <= 0) {
                  alert('Kolicina ne sme biti negativan broj niti nula');
                  return;
                }
                if (addedProducts.length == 0) {
                  alert('Izaberite proizvod');
                  return;
                }
                if (addedProducts.length > 1) {
                  alert('Mozete izabrati samo jedan proizvod');
                  return;
                }

                setOrderItems([
                  ...orderItems,
                  {
                    products: addedProducts,
                    amount,
                    estimatedEnd: itemEstimatedEndRef.current?.value,
                    priority: priorityRef.current?.value,
                  } as OrderItem,
                ]);
                setAddedProducts([]);
                setOrderId('');
                setAmount(-1);
                onClose();
              }}
            >
              Dodaj
            </Button>
            <Button
              onClick={onClose}
              _hover={{ textDecor: 'none', bgColor: logoutBtnClr }}
              variant='outline'
            >
              Izadji
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default EditOrderPage;
