import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react';
import { FC } from 'react';
import { TableDataDTO, WorkerBySectorDTO } from '../worker-api';
import WorkerRow from './worker-row';

const WorkerTable: FC<{
  data: TableDataDTO['workerPageDTO'];
  workers: WorkerBySectorDTO[];
  sectorMinWorkTime: number;
  onTaskFinishedHandler: () => void;
  isChecked: boolean;
  handleInitalAndRefreshCalls: any;
  setIsAnyModalOpen: any;
  isAnyModalOpen: boolean;
  defaultWorker: WorkerBySectorDTO | undefined;
}> = ({
  data,
  workers,
  sectorMinWorkTime,
  onTaskFinishedHandler,
  isChecked,
  handleInitalAndRefreshCalls,
  setIsAnyModalOpen,
  isAnyModalOpen,
  defaultWorker,
}) => {
  return (
    <TableContainer>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th textAlign='center'>Šifra porudžbine</Th>
            <Th textAlign='center'>Datum isporuke</Th>
            <Th textAlign='center'>Šifra proizvoda</Th>
            <Th textAlign='center'>Količina</Th>
            <Th textAlign='center'>Prioritet</Th>
            <Th textAlign='center'>Instrukcije </Th>
            <Th textAlign='center'>Skica proizvoda</Th>
            <Th textAlign='center'>Crtež proizvoda</Th>
            <Th textAlign='center'>Izvođač radova</Th>
            <Th textAlign='center'>Materijal</Th>
            <Th textAlign='center'>Izrada</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row) => (
            <WorkerRow
              key={`${row.productId}-${row.orderItemId}-${row.orderCode}-${row.phaseId}`}
              dataRow={row}
              workers={workers}
              sectorMinWorkTime={sectorMinWorkTime}
              onTaskFinishedHandler={onTaskFinishedHandler}
              handleInitalAndRefreshCalls={handleInitalAndRefreshCalls}
              isChecked={isChecked}
              setIsAnyModalOpen={setIsAnyModalOpen}
              isAnyModalOpen={isAnyModalOpen}
              defaultWorker={defaultWorker}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default WorkerTable;
