import {
  useColorModeValue,
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Select,
} from '@chakra-ui/react';
import { useState, useRef, FC, useEffect } from 'react';
import {
  createSector,
  getUsersForSector,
  Sector,
  User,
} from '../../sector-apis';
import GsSpinner from '../../../../../components/spinner/gsSpinner';
import { checkTwoSpaces } from '../../../../../utilility/util';
import { useTranslation } from 'react-i18next';
import GsBtn from '../../../../../components/button/GsBtn';

const SectorForm: FC<{
  selectedSector?: Sector;
  onSubmit?: () => Promise<void>;
}> = ({ selectedSector, onSubmit }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const saveBtnColor = useColorModeValue('#EBF5FB', '#2D3748');
  const sectorNameRef = useRef<HTMLInputElement>(null);
  const sectorMinWorkTimeRef = useRef<HTMLInputElement>(null);
  const selectedUserRef = useRef<HTMLSelectElement>(null);
  const [usersForSector, setUsersForSector] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<number | undefined>(
    selectedSector?.defaultUserId
  );
  const { t } = useTranslation();

  const loadUsersForSector = async () => {
    try {
      if (selectedSector?.id) {
        const users = await getUsersForSector(selectedSector?.id);
        setUsersForSector(users);
      }
    } catch (error) {
      console.error('Failed to load users for sector:', error);
    }
  };

  const handleUserChange = () => {
    if (selectedUserRef.current?.value) {
      setSelectedUser(Number(selectedUserRef.current?.value));
    }
  };

  useEffect(() => {
    if (selectedSector) {
      sectorNameRef.current!.value = selectedSector.name as string;
      sectorMinWorkTimeRef.current!.valueAsNumber =
        selectedSector.minWorkTime as number;
      loadUsersForSector();
    }
  }, []);

  const validateInputsAndSaveSector = async () => {
    if (sectorNameRef === null || sectorNameRef.current?.value === '') {
      alert('Unesite ime');
      return;
    }

    if (
      sectorNameRef &&
      sectorNameRef.current?.value &&
      checkTwoSpaces(sectorNameRef.current?.value)
    ) {
      alert(
        'Nisu dozvoljena dva uzastopna znaka razmaka kod naziva materijala'
      );
      return;
    }

    const sectorMinWorkTime = parseFloat(
      sectorMinWorkTimeRef.current?.value as string
    );

    if (isNaN(sectorMinWorkTime)) {
      alert('Minimalno vreme radnika nije validno ili ne postoji');
      return;
    }

    if (sectorMinWorkTime < 0) {
      alert('Minimalno vreme rada ne moze biti manje od 0');
      return;
    }

    const sector: Sector = {
      name: sectorNameRef.current!.value,
      minWorkTime: sectorMinWorkTimeRef.current!.valueAsNumber,
      id: selectedSector?.id,
      defaultUserId: selectedUser,
    };
    setIsLoading(true);
    const success = await createSector(sector);
    setIsLoading(false);

    if (success) {
      sectorNameRef.current!.value = '';
      sectorMinWorkTimeRef.current!.value = '';
      alert('Sektor uspesno sacuvan');
    } else {
      alert('Greska pri cuvanju sektora');
    }

    //for the edit sector page
    if (onSubmit) await onSubmit();
  };

  return (
    <Box px='5'>
      <Flex flexDirection='row' gap='6'>
        <Flex
          flexDirection='column'
          mb='50'
          gap='6'
          width={500}
          alignItems='flex-start'
          position='relative'
        >
          <FormControl width='60%'>
            <FormLabel>{t('sector-name')}</FormLabel>
            <Input
              type='text'
              ref={sectorNameRef}
              placeholder='Unesite ime za novi sektor'
              sx={{ '::placeholder': { fontStyle: 'italic' } }}
            />
          </FormControl>
          <FormControl width='60%'>
            <FormLabel>{t('min-work-time')}</FormLabel>
            <Input
              type='number'
              ref={sectorMinWorkTimeRef}
              placeholder='Unesite minimalno vreme rada za radnike'
              sx={{ '::placeholder': { fontStyle: 'italic' } }}
            />
          </FormControl>
        </Flex>

        <Flex
          flexDirection='column'
          mb='50'
          gap='6'
          width={500}
          alignItems='flex-start'
          position='relative'
        >
          {selectedSector ? (
            <FormControl>
              <FormLabel>{t('choose-default-worker-for-sector')}</FormLabel>
              <Select
                placeholder={t('no-default')}
                ref={selectedUserRef}
                value={selectedUser}
                onChange={handleUserChange}
              >
                {usersForSector.map((user, index) => (
                  <option key={index} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          ) : (
            <FormControl>
              <i>{t('create-sector-default-user')}</i>
            </FormControl>
          )}

          {isLoading ? (
            <GsSpinner />
          ) : (
            <Box marginTop='100px' alignSelf='flex-end'>
              <GsBtn
                text={t('save')}
                onClick={() => validateInputsAndSaveSector()}
              ></GsBtn>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default SectorForm;
