import {
  Box,
  Flex,
  Table,
  Tr,
  Td,
  Progress,
  Tbody,
  Thead,
  Th,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Select,
  FormLabel,
  IconButton,
  useColorModeValue,
  InputLeftElement,
  Icon,
  InputGroup,
  TableCaption,
  FormControl,
  InputRightElement,
  Avatar,
  useTheme,
  TableContainer,
  Tfoot,
  Spacer,
} from '@chakra-ui/react';
import { useChakra } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import {
  getOrdersWithProgress,
  OrderWithProgress,
  OrderItemWithProgress,
  getOrderItemsWithProgress,
  getPhasesProgress,
  PhasesProgress,
  PhasesTableProps,
  getOrdersWithProgressByFilter,
  convertDate,
  createFormatedDate,
  updateOrderStatusToManuallyComplete,
  WorkerBySectorDTO,
  assignWorker,
} from './order-apis';
import { FaSearch, FaUser } from 'react-icons/fa';
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ChatIcon,
  CheckCircleIcon,
  CheckIcon,
  SearchIcon,
} from '@chakra-ui/icons';
import { DatepickerConfigs, RangeDatepicker } from 'chakra-dayzed-datepicker';
import NoteModal from './modal/note-modal';
import { exportToExcel } from '../../../functions/export';
import { TableNavigation } from '../../../components/TabeNavigation/TableNavigation';
import TaskFinishModal from './modal/taskFinishModal';
import { OrderItemPhaseDTO } from '../../worker/worker-api';
import { getTaskColor } from '../../../utilility/util';
import {
  COLOR_OVERVIEW_ORDER_ITEM,
  COLOR_SEARCH_HIGHLIGHT,
} from '../../../utilility/colorConstatns';
import { User } from '../../login/login-api';
import OrderPriority from './components/orderPriority';
import GsIconBtn from '../../../components/button/GsIconBtn';
import { AnimatePresence, motion } from 'framer-motion';
import GsBtn from '../../../components/button/GsBtn';
import GsHeaderModal from '../../../components/heading/GsHeaderModal';
import AssignWorkerPopOver from './components/popover/AssignWorkerPopOver';
import {
  ALERT_GREEN,
  ALERT_RED,
  useGsDialog,
} from '../../../contexts/GsConfirmDialogContext';
import ShowWorkersForTaskModal from './modal/ShowWorkersForTaskModal';

const OverviewOrderPage = () => {
  const defaultSearchType = 'ORDER';

  const { theme, colorMode } = useChakra();
  const [orders, setOrders] = useState<OrderWithProgress[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [configs, setConfigs] = useState<DatepickerConfigs>({
    dateFormat: 'dd/MM/yyyy',
    firstDayOfWeek: 1,
  });
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedSort, setSelectedSort] = useState('');
  const [searchType, setSearchType] = useState(defaultSearchType);
  const searchRef = useRef<HTMLInputElement>(null);

  const SEARCH_TYPE_ORDER = 'ORDER';
  const SEARCH_TYPE_PRODUCT = 'PRODUCT';

  const defaultDateRange = 'ESTIMATED_END';
  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange);

  const DATE_RANGE_TYPE_DATE_CREATED = 'DATE_CREATED';
  const DATE_RANGE_TYPE_ESTIMATED_END = 'ESTIMATED_END';
  const DATE_RANGE_TYPE_REAL_END = 'REAL_END';

  useEffect(() => {
    getOrdersWithProgress(setOrders, 0);
  }, [setOrders]);

  const [selectedDates, setSelectedDates] = useState<Date[]>([
    // new Date(),
    // new Date(),
  ]);

  const handleSelectChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedValue(event.target.value);
  };

  const handleSelectSort = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedSort(event.target.value);
  };

  const handleSelectedSearchType = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchType(event.target.value);
  };

  const handleSelectedDateRange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedDateRange(event.target.value);
  };

  const normalizeString = (str: string): string =>
    str.replace(/\s+/g, '').toLowerCase();

  const compareProductCode = (productCode: string, filter: string): boolean =>
    normalizeString(productCode).includes(normalizeString(filter));

  const getRowColor = (productCode: string) => {
    if (
      filter !== '' &&
      searchType.includes(SEARCH_TYPE_PRODUCT) &&
      compareProductCode(productCode, filter)
    )
      return COLOR_SEARCH_HIGHLIGHT;
    return COLOR_OVERVIEW_ORDER_ITEM;
  };

  return (
    <Flex
      flexDir='column'
      p='20'
      bg={theme.colors.bg.colorMode(colorMode)}
      borderRadius='10'
      borderWidth={2}
      // minWidth='max-content'
    >
      <Flex flexDirection='column' marginBottom='5%'>
        <InputGroup alignItems='center'>
          <InputLeftElement
            pointerEvents='none'
            children={
              <Icon
                as={FaSearch}
                color='gray.500'
                fontSize='20px'
                marginTop='10px'
              />
            }
          />
          <Input
            w='25%'
            marginBottom='30px'
            placeholder='Pretraži'
            // value={filter}
            style={{ height: '50px' }}
            ref={searchRef}
            onChange={(e) => {
              // if (!e.target.value || e.target.value.length === 0) return;
              if (e.target.value.length === 0) {
                getOrdersWithProgressByFilter(
                  '',
                  setOrders,
                  selectedDates,
                  selectedValue,
                  selectedSort,
                  searchType,
                  selectedDateRange,
                  0
                );
                setFilter('');
              } else {
                getOrdersWithProgressByFilter(
                  e.target.value,
                  setOrders,
                  selectedDates,
                  selectedValue,
                  selectedSort,
                  searchType,
                  selectedDateRange,
                  0
                );
                setFilter(e.target.value);
              }
            }}
          ></Input>
        </InputGroup>
        <Flex wrap='wrap'>
          <Flex justify='flex-start' maxWidth='50%' mr='40' wrap='wrap'>
            <FormControl mr='10' flex='1'>
              <FormLabel whiteSpace='nowrap'> Izbor pretrage</FormLabel>
              <Select
                value={searchType}
                onChange={handleSelectedSearchType}
                defaultValue={defaultSearchType}
              >
                <option value={SEARCH_TYPE_ORDER}>Po porudzbenici</option>
                <option value={SEARCH_TYPE_PRODUCT}>Po proizvodu</option>
              </Select>
            </FormControl>
            <FormControl mr='10' flex='1'>
              <FormLabel whiteSpace='nowrap'> Izbor statusa</FormLabel>
              <Select
                placeholder='/'
                value={selectedValue}
                onChange={handleSelectChange}
              >
                <option value='IN_PROGRESS'>U Izradi</option>
                <option value='COMPLETE'>Zavrsene</option>
              </Select>
            </FormControl>
            <FormControl mr='10' flex='1'>
              <FormLabel whiteSpace='nowrap'> Sortiraj po </FormLabel>
              <Select
                placeholder='/'
                value={selectedSort}
                onChange={handleSelectSort}
              >
                <option value='estimated_start_desc'>
                  Datum prijema silazno
                </option>
                <option value='estimated_start_asc'>
                  Datum prijema uzlazno
                </option>
                <option value='estimated_end_desc'>
                  Željena isporuka silazno
                </option>
                <option value='estimated_end_asc'>
                  Željena isporuka uzlazno
                </option>
                <option value='real_end_desc'>Stvarna isporuka silazno</option>
                <option value='real_end_asc'>Stvarna isporuka uzlazno</option>
              </Select>
            </FormControl>
            <FormControl mr='10' flex='2'>
              <Flex justifyContent='space-between'>
                <FormLabel whiteSpace='nowrap'>Opseg datuma</FormLabel>
                <Select
                  value={selectedDateRange}
                  defaultValue={defaultDateRange}
                  onChange={handleSelectedDateRange}
                  size='sm'
                  rounded='md'
                >
                  <option value={DATE_RANGE_TYPE_DATE_CREATED}>Prijem</option>
                  <option value={DATE_RANGE_TYPE_ESTIMATED_END}>
                    Zeljena isporuka
                  </option>
                  <option value={DATE_RANGE_TYPE_REAL_END}>
                    Stvarna isporuka
                  </option>
                </Select>
              </Flex>
              <RangeDatepicker
                configs={configs}
                selectedDates={selectedDates}
                onDateChange={setSelectedDates}
              />
            </FormControl>
          </Flex>
          <Flex mt='8' justify='flex-end' gap='2'>
            <GsBtn
              text='Primeni filtere'
              onClick={() => {
                getOrdersWithProgressByFilter(
                  filter,
                  setOrders,
                  selectedDates,
                  selectedValue,
                  selectedSort,
                  searchType,
                  selectedDateRange,
                  0
                );
              }}
            />
            <GsBtn
              text='Resetuj filtere'
              onClick={() => {
                setSelectedValue('');
                setFilter('');
                setSelectedSort('');
                setSelectedDates([]);
                getOrdersWithProgress(setOrders, 0);
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      <OrderTable
        orders={orders}
        setOrders={setOrders}
        fieldRef={searchRef}
        selectedDates={selectedDates}
        status={selectedValue}
        selectedSort={selectedSort}
        searchType={searchType}
        selectedDateRange={selectedDateRange}
        getRowColor={getRowColor}
      />
    </Flex>
  );
};

function ExpandableTableRow({
  orders,
  order,
  setOrders,
  fieldRef,
  selectedDateRange,
  selectedDates,
  status,
  selectedSort,
  searchType,
  getRowColor,
  pageNumber,
}: OrderProgressTableProps) {
  const logoutBtnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    if (!isOpen) {
      setIsHover(true);
    }
  };
  const handleMouseLeave = () => {
    if (!isOpen) {
      setIsHover(false);
    }
  };
  const boxStyle = {
    borderBottom: '1px solid #ccc',
    backgroundColor: isHover ? logoutBtnClr : '',
    cursor: isHover ? 'pointer' : 'default',
  };
  const [isOpen, setIsOpen] = useState(false);
  const [orderItemsWithProgress, setOrderItemsWithProgress] = useState<
    OrderItemWithProgress[]
  >([]);
  const [orderItemsWithProgressFiltered, setOrderItemsWithProgressFiltered] =
    useState<OrderItemWithProgress[]>([]);
  const [phasesProgress, setPhasesProgress] = useState<PhasesProgress[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const btnClr = useColorModeValue('#EBF5FB', '#2D3748');
  const { confirmDialog, alertDialog } = useGsDialog();

  type Default<T> = {
    [K in keyof T]: T[K];
  };
  const defaultOrderItem: Default<OrderItemWithProgress> = {
    id: 0,
    item_code: '',
    product_code: '',
    total_tasks: 0,
    new_tasks: 0,
    in_progress_tasks: 0,
    end_tasks: 0,
    estimated_end: '',
    is_started: 0,
  };
  const [selectedOrderItem, setSelectedOrderItem] =
    useState<OrderItemWithProgress>(defaultOrderItem);

  useEffect(() => {
    if (orderItemsWithProgress)
      setOrderItemsWithProgressFiltered(orderItemsWithProgress);
  }, [orderItemsWithProgress]);

  const handleButtonClick = (oip: OrderItemWithProgress) => {
    setSelectedOrderItem(oip);
    if (oip?.id) {
      getPhasesProgress(setPhasesProgress, oip.id);
      setIsOpenModal(true);
    }
  };

  const handleFinishOrderManually = async (orderId: number) => {
    confirmDialog(
      'Završite porudžbinu',
      'Da li sigurno želite da završite porudžbinu?',
      async () => {
        const success = await updateOrderStatusToManuallyComplete(orderId);
        if (success) {
          alertDialog(
            'Porudžbina završena',
            'Uspešno ste završili porudžbinu.',
            ALERT_GREEN
          );

          getOrdersWithProgressByFilter(
            fieldRef.current.value,
            setOrders,
            selectedDates,
            status,
            selectedSort,
            searchType,
            selectedDateRange,
            pageNumber as number
          );
        } else
          alertDialog(
            'Greška',
            'Greška prilikom završavanja porudžbine.',
            ALERT_RED
          );
      }
    );
  };

  const handleGetPhasesProgress = (orderItemId: number) => {
    getPhasesProgress(setPhasesProgress, orderItemId);
  };

  if (order != null)
    return (
      <>
        <Tr
          style={boxStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={(e) => {
            setIsOpen(!isOpen);
            if (order?.id != undefined)
              getOrderItemsWithProgress(setOrderItemsWithProgress, order.id);
          }}
        >
          <Td>
            {isOpen ? (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                width='30px'
                height='30px'
                borderRadius='50%'
                backgroundColor='#002244'
                marginRight='30px'
              >
                <ArrowDownIcon />
              </Box>
            ) : (
              <ArrowRightIcon marginRight='30px' />
            )}
          </Td>
          <Td textAlign='center'>{order?.order_code}</Td>
          <Td textAlign='center'> {convertDate(order?.estimated_start)} </Td>
          <Td textAlign='center'> {convertDate(order?.estimated_end)}</Td>
          <Td textAlign='center'> {convertDate(order?.real_end)}</Td>
          <Td textAlign='center'>
            {' '}
            <OrderPriority priority={order?.priority} />{' '}
          </Td>
          <Td>
            <Flex
              alignItems='center'
              justifyContent='center'
              flexDirection='row'
            >
              <Box w='30%' marginRight='30%'>
                <Progress
                  value={
                    order?.status === 'IN_PROGRESS'
                      ? (order?.end_tasks / order?.total_tasks) * 100
                      : 100
                  }
                  width='40'
                  colorScheme={
                    order?.status === 'IN_PROGRESS'
                      ? order.end_tasks / order.total_tasks > 0.5
                        ? 'green'
                        : 'red'
                      : 'green' // Assuming you want the progress bar to be green when complete
                  }
                />
              </Box>
              <Box w='20%' fontWeight='bold'>
                {order?.status === 'IN_PROGRESS'
                  ? `${Math.round(
                      (order?.end_tasks / order?.total_tasks) * 100
                    )} %`
                  : '100 %'}
              </Box>
            </Flex>
          </Td>
          <Td>
            <Flex justifyContent='center'>
              <GsIconBtn
                icon={<CheckIcon />}
                label='finish order manually'
                onClick={() => handleFinishOrderManually(order.id)}
                disabled={
                  order?.status === 'COMPLETE' ||
                  order?.status === 'MANUALLY COMPLETE'
                }
              />
            </Flex>
          </Td>
        </Tr>

        <AnimatePresence>
          {isOpen && (
            <motion.tr
              initial='collapsed'
              animate='open'
              exit='collapsed'
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.4, ease: 'easeIn' }}
            >
              <Td colSpan={8}>
                <TableContainer whiteSpace='normal'>
                  <Table>
                    <Thead>
                      <Tr
                        style={{
                          backgroundColor: '#002244',
                        }}
                      >
                        <Td fontWeight='bold' textAlign='center'>
                          Redni broj
                        </Td>
                        <Td fontWeight='bold' textAlign='center'>
                          Šifra proizvoda
                        </Td>
                        <Td fontWeight='bold' textAlign='center'>
                          Zapoceto
                        </Td>
                        <Td fontWeight='bold' textAlign='center'>
                          Broj operacija
                        </Td>
                        <Td fontWeight='bold' textAlign='center'>
                          Urađene operacije
                        </Td>
                        <Td fontWeight='bold' textAlign='center'>
                          Isporuka stavke
                        </Td>
                        <Td fontWeight='bold' textAlign='center'>
                          Stanje
                        </Td>
                        <Td textAlign='center' alignItems='center'>
                          <InputGroup>
                            <Input
                              placeholder='Pretražite proizvod'
                              w='max-content'
                              onChange={(e) => {
                                const filter = e.target.value.toLowerCase();
                                const filteredOrderItems =
                                  orderItemsWithProgress.filter((item) =>
                                    item.product_code
                                      .toLowerCase()
                                      .includes(filter)
                                  );
                                setOrderItemsWithProgressFiltered(
                                  filteredOrderItems
                                );
                              }}
                            />
                            <InputRightElement
                              children={
                                <GsIconBtn
                                  icon={<SearchIcon />}
                                  label='find product'
                                  onClick={() => {}}
                                />
                              }
                            />
                          </InputGroup>
                        </Td>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {orderItemsWithProgressFiltered.map(
                        (oip: OrderItemWithProgress, index: number) => (
                          <Tr
                            key={index}
                            backgroundColor={getRowColor(oip.product_code)}
                          >
                            <Td textAlign='center'> {index + 1} </Td>
                            <Td fontWeight='bold' textAlign='center'>
                              {oip.product_code}
                            </Td>
                            <Td>
                              {oip.is_started === 1 && (
                                <CheckCircleIcon
                                  ml='50%'
                                  boxSize={7}
                                  color='white'
                                />
                              )}
                            </Td>
                            <Td textAlign='center'>{oip.total_tasks}</Td>
                            <Td textAlign='center'>{oip.end_tasks}</Td>
                            <Td textAlign='center'>
                              {convertDate(oip.estimated_end)}
                            </Td>
                            <Td>
                              <Flex alignItems='center' justifyContent='center'>
                                <Progress
                                  value={
                                    (oip?.end_tasks / oip?.total_tasks) * 100
                                  }
                                  width='40'
                                  colorScheme={
                                    oip.end_tasks / oip.total_tasks > 0.5
                                      ? 'green'
                                      : 'red'
                                  }
                                />
                              </Flex>
                            </Td>
                            <Td textAlign='center'>
                              <GsBtn
                                text='Detalji'
                                onClick={() => handleButtonClick(oip)}
                              />
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>

                <PhasesModal
                  setIsOpenModal={setIsOpenModal}
                  isOpenModal={isOpenModal}
                  order={order}
                  orderItem={selectedOrderItem}
                  phases={phasesProgress}
                  handleGetPhasesProgress={handleGetPhasesProgress}
                />
              </Td>
            </motion.tr>
          )}
        </AnimatePresence>
      </>
    );
  else {
    return <></>;
  }
}

export interface OrderProgressTableProps {
  orders: OrderWithProgress[];
  order?: OrderWithProgress;
  setOrders: any;
  fieldRef: any;
  selectedDates: Date[];
  status: string;
  selectedSort: string;
  searchType: string;
  selectedDateRange: string;
  getRowColor: (productCode: string) => string;
  pageNumber?: number;
}

function OrderTable({
  orders,
  setOrders,
  fieldRef,
  selectedDates,
  status,
  selectedSort,
  searchType,
  selectedDateRange,
  getRowColor,
}: OrderProgressTableProps) {
  const [pageNumber, setPageNumber] = useState<number>(0);

  const pageNumberHandler = (num: number) => {
    let newPageNumber = -1;
    if (num === 1) newPageNumber = pageNumber + 1;
    else if (num === -1) newPageNumber = pageNumber > 0 ? pageNumber - 1 : 0;

    getOrdersWithProgressByFilter(
      fieldRef.current.value,
      setOrders,
      selectedDates,
      status,
      selectedSort,
      searchType,
      selectedDateRange,
      newPageNumber
    );
    setPageNumber(newPageNumber);
  };

  return (
    <TableContainer whiteSpace='normal'>
      <Table>
        <Thead>
          <Tr borderBottomWidth='2px' borderBottomColor='white'>
            <Th />
            <Th textAlign='center'>Šifra porudžbine</Th>
            <Th textAlign='center'>Datum prijema</Th>
            <Th textAlign='center'>Željeni Datum isporuke</Th>
            <Th textAlign='center'>Stvarni Datum isporuke</Th>
            <Th textAlign='center'>Prioritet</Th>
            <Th textAlign='center'>Stanje</Th>
            <Th textAlign='center'>Završi porudžbinu</Th>
          </Tr>
        </Thead>

        <Tbody>
          {orders.map((o: OrderWithProgress) => (
            <ExpandableTableRow
              key={o.order_code}
              order={o}
              orders={orders}
              fieldRef={fieldRef}
              setOrders={setOrders}
              selectedDates={selectedDates}
              status={status}
              selectedSort={selectedSort}
              searchType={searchType}
              selectedDateRange={selectedDateRange}
              getRowColor={getRowColor}
              pageNumber={pageNumber}
            />
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan={8}>
              <Flex justify='center' mt='10'>
                <TableNavigation
                  pageNumber={pageNumber}
                  pageNumberHandler={pageNumberHandler}
                />
              </Flex>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
}

function PhasesModal({
  setIsOpenModal,
  isOpenModal,
  order,
  orderItem,
  phases,
  handleGetPhasesProgress,
}: PhasesTableProps) {
  const onClose = () => setIsOpenModal(false);
  const onOpen = () => setIsOpenModal(true);
  const currentUser: User = JSON.parse(localStorage.getItem('user') as string);

  const sum = phases.reduce(
    (totalProductTime, element) =>
      totalProductTime +
      (element.total_time !== null ? Number(element.total_time) : 0),
    0
  );

  let formatedTotalTime = sum ? createFormatedDate(sum) : '';

  const handleExportButtonClick = async () => {
    try {
      const headers = [
        'Naziv faze',
        'Početak rada',
        'Završetak rada',
        'Trajanje rada',
        'Instrukcije',
        'Kompletirano',
        'Potrebno',
        'Radnik',
        'Napomena',
        'Status',
      ];

      const data = phases.map((item: PhasesProgress) => [
        item.name,
        item.start_time
          ? new Date(item.start_time)
              .toLocaleString('en-GB', {
                year: 'numeric',
                day: '2-digit',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })
              .replace(/\//g, '-')
          : '',
        item.end_time
          ? new Date(item.end_time)
              .toLocaleString('en-GB', {
                year: 'numeric',
                day: '2-digit',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })
              .replace(/\//g, '-')
          : '',
        item.start_time && item.end_time
          ? createFormatedDate(item.total_time)
          : '',
        item.instructions,
        item.completed_amount ? item.completed_amount : '',
        item.expected_amount,
        item.workerName,
        item.note ? item.note : '',
        item.status === 'COMPLETE'
          ? 'Zavrseno'
          : item.status === 'IN_PROGRESS' && item.start_time
          ? 'U Izradi'
          : 'Nije Zapoceto',
      ]);

      let delivery_date;
      orderItem.estimated_end
        ? (delivery_date = new Date(orderItem.estimated_end)
            .toLocaleString('en-GB', {
              year: 'numeric',
              day: '2-digit',
              month: '2-digit',
            })
            .replace(/\//g, '-'))
        : (delivery_date = new Date(order.estimated_end)
            .toLocaleString('en-GB', {
              year: 'numeric',
              day: '2-digit',
              month: '2-digit',
            })
            .replace(/\//g, '-'));

      const reportName =
        order.order_code +
        ' - ' +
        orderItem.product_code +
        ' - ' +
        delivery_date;
      exportToExcel(headers, data, reportName);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  return (
    <Modal isOpen={isOpenModal} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={{ base: '90%', md: '70%', lg: '60%' }} minWidth='80%'>
        <GsHeaderModal title={'Proizvod: ' + orderItem.product_code} />
        <ModalCloseButton />
        <ModalBody>
          <TableContainer whiteSpace='normal'>
            <Table>
              <Thead>
                <Tr>
                  <Th textAlign='center'>Naziv faze</Th>
                  {currentUser.role === 'ROLE_MANAGER' && (
                    <>
                      <Th textAlign='center'>Početak rada</Th>
                      <Th textAlign='center'>Trajanje rada </Th>
                    </>
                  )}
                  <Th textAlign='center'>Završetak rada </Th>
                  <Th textAlign='center'>Instrukcije </Th>
                  <Th textAlign='center'>Kompletirano</Th>
                  <Th textAlign='center'> Potrebno </Th>
                  <Th textAlign='center'> Radnik</Th>
                  <Th textAlign='center'> Napomena </Th>
                  <Th textAlign='center'> Zavrsi </Th>
                </Tr>
              </Thead>

              <Tbody>
                {phases.map((p: PhasesProgress) => (
                  <PhaseTableRow
                    phase={p}
                    orderItemId={orderItem.id}
                    handleGetPhasesProgress={handleGetPhasesProgress}
                    user={currentUser}
                    orderCode={order.order_code}
                    productCode={orderItem.product_code}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Box>
            <span style={{ fontSize: 'larger', fontWeight: 'bold' }}>
              Ukupno trajanje rada:
            </span>
            <span
              style={{
                margin: '0 10px',
                fontSize: 'larger',
                fontWeight: 'bold',
              }}
            >
              {formatedTotalTime}
            </span>
          </Box>
          {currentUser.role === 'ROLE_MANAGER' && (
            <GsBtn text='Eksportuj faze' onClick={handleExportButtonClick} />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export interface PhaseRowTableProps {
  phase: PhasesProgress;
  orderItemId: number;
  handleGetPhasesProgress: any;
  user: User;
  productCode?: string;
  orderCode?: string;
}

function PhaseTableRow({
  phase,
  orderItemId,
  handleGetPhasesProgress,
  user,
  productCode,
  orderCode,
}: PhaseRowTableProps) {
  const avatarClr = useTheme().colors.blue[700];
  const dateStringStart = phase?.start_time;
  let formattedDateStart;
  if (dateStringStart) {
    const dateStart = new Date(dateStringStart);
    formattedDateStart = dateStart
      .toLocaleString('en-GB', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .replace(/\//g, '-');
  } else {
    formattedDateStart = ',';
  }

  const dateStringEnd = phase?.end_time;
  let formattedDateEnd;
  if (dateStringEnd) {
    const dateEnd = new Date(dateStringEnd);
    formattedDateEnd = dateEnd
      .toLocaleString('en-GB', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .replace(/\//g, '-');
  } else {
    formattedDateEnd = ',';
  }

  let formatedDateDuration =
    dateStringEnd && dateStringStart
      ? createFormatedDate(phase.total_time)
      : '';

  const backgroundColor = getTaskColor(phase.status);

  const {
    isOpen: isShowModalOpen,
    onOpen: onShowModalOpen,
    onClose: onShowModalClose,
  } = useDisclosure();

  const {
    isOpen: isOpenTaskFinishModal,
    onOpen: onOpenTaskFinishModal,
    onClose: onCloseTaskFinishModal,
  } = useDisclosure();

  const {
    isOpen: isOpenTaskAssignmentWorker,
    onOpen: onOpenTaskAssignmentWorker,
    onClose: onCloseTaskAssignmentWorker,
  } = useDisclosure();

  const {
    isOpen: isOpenAllWorkersForTask,
    onOpen: onOpenAllWorkersForTask,
    onClose: onCloseAllWorkersForTask,
  } = useDisclosure();

  const { confirmDialog, alertDialog } = useGsDialog();
  const loggedInUser: User = JSON.parse(localStorage.getItem('user') as string);
  const itemPhase: OrderItemPhaseDTO = {
    orderItemId: orderItemId,
    phaseId: phase.phase_id,
    phasePid: phase.phase_pid,
    taskId: phase.task_id,
    assignedById: Number(loggedInUser.userId),
  };
  let shouldCallStartTask = false;
  if (formattedDateStart == ',') shouldCallStartTask = true;

  const onAssignWorkerHanlder = async (worker: WorkerBySectorDTO) => {
    const itemPhaseUser = { ...itemPhase, workerId: worker.userId };
    const newWorker = await assignWorker(itemPhaseUser);
    if (newWorker) {
      handleGetPhasesProgress(orderItemId);
      alertDialog(
        'Izmena sačuvana ',
        'Uspešno ste dodelili task radniku.',
        ALERT_GREEN
      );
    }
  };

  return (
    <Tr
      style={{
        borderBottom: '1px solid #ccc',
      }}
      backgroundColor={backgroundColor}
    >
      <Td textAlign='center'>{phase.name}</Td>
      {user.role === 'ROLE_MANAGER' && (
        <>
          <Td textAlign='center'>{formattedDateStart.replace(',', '')}</Td>
          <Td textAlign='center'>{formatedDateDuration} </Td>
        </>
      )}
      <Td textAlign='center'>{formattedDateEnd.replace(',', '')}</Td>
      <Td textAlign='center'>{phase.instructions} </Td>
      <Td textAlign='center'>{phase.completed_amount}</Td>
      <Td textAlign='center'>{phase.expected_amount} </Td>
      <Td textAlign='center'>
        <AssignWorkerPopOver
          buttonTrigger={
            <Button
              style={{ background: 'transparent' }}
              onMouseEnter={onOpenTaskAssignmentWorker}
              onClick={onOpenAllWorkersForTask}
            >
              {phase.workerName ? (
                <Avatar
                  size='md'
                  bg={avatarClr}
                  textColor='white'
                  name={phase.workerName}
                />
              ) : (
                <Avatar icon={<FaUser />} bg={avatarClr} size='md' />
              )}
            </Button>
          }
          isOpen={isOpenTaskAssignmentWorker}
          onClose={onCloseTaskAssignmentWorker}
          workerName={phase.workerName}
          sectorId={phase.sectorId}
          taskStartTime={phase.start_time}
          onAssignWorker={onAssignWorkerHanlder}
        />
        <ShowWorkersForTaskModal
          isOpen={isOpenAllWorkersForTask}
          onClose={onCloseAllWorkersForTask}
          sectorName={phase.name}
          taskId={phase.task_id}
          productCode={productCode as string}
          orderCode={orderCode as string}
        />
      </Td>
      <Td textAlign='center'>
        {phase.note && (
          <GsIconBtn
            icon={<ChatIcon />}
            label='addFalloutToMaterial'
            onClick={onShowModalOpen}
          />
        )}
      </Td>
      <Td textAlign='center'>
        <GsIconBtn
          label='finishTask'
          icon={<CheckIcon />}
          onClick={onOpenTaskFinishModal}
          disabled={phase.status === 'COMPLETE'}
        />
      </Td>
      <NoteModal
        isOpen={isShowModalOpen}
        onClose={onShowModalClose}
        note={phase.note}
      />
      <TaskFinishModal
        isOpen={isOpenTaskFinishModal}
        onClose={onCloseTaskFinishModal}
        orderItemDTO={itemPhase}
        shouldCallStartTask={shouldCallStartTask}
        handleGetPhasesProgress={handleGetPhasesProgress}
      />
    </Tr>
  );
}

export default OverviewOrderPage;
