import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Sector } from '../../sector-apis';
import SectorTableRow from './sector-table-row';

const SectorTable: FC<{
  sectors: Sector[];
  onSectorSelect: (sector: Sector) => void;
}> = ({ sectors, onSectorSelect }) => {
  const { t } = useTranslation();
  return (
    <TableContainer marginTop='30px' marginBottom='70px' width='100%'>
      <Table variant='simple'>
        <TableCaption>{sectors ? 'Sektori' : 'Nema sektora'}</TableCaption>
        <Thead>
          <Tr>
            <Th w='70%'>{t('sector-name')}</Th>
            <Th w='15%'>{t('edit-sector')}</Th>
            <Th w='15%'>{t('delete-sector')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sectors.map((s) => (
            <SectorTableRow
              key={s.id}
              sector={s}
              onSectorSelect={onSectorSelect}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SectorTable;
