import { GET, POST } from '../../../api/requests';
import { Exception510 } from '../../../exceptions/Exception510';
import { getPaginationParams } from '../../../components/TabeNavigation/table-navigation';
import { SERVER_URL } from '../../../api/config';
import { NumericLiteral } from 'typescript';
import { OrderItemPhaseDTO } from '../../worker/worker-api';
import {
  GET_ORDER_ITEMS,
  UPDATE_ORDER_STATUS,
} from '../../../api/apiConstants';
import { NoContentException } from '../../../exceptions/NoContentException';
import { Resource } from '../resource/resource-api';
import { MeasurementUnit } from '../measurementUnit/measurementUnit-api';

export type Product = {
  id: number;
  description: string;
  productCode: string;
  measureUnit: MeasurementUnit;
  isAdded: boolean;
};

export type OrderItem = {
  id?: number;
  products: Product[];
  amount: number;
  estimatedEnd?: string;
  priority: string;
};

export type OrderItemWithProgress = {
  id: number;
  item_code: string;
  product_code: string;
  total_tasks: number;
  new_tasks: number;
  in_progress_tasks: number;
  end_tasks: number;
  estimated_end: string;
  is_started: number;
  status?: string;
};
export type PhasesProgress = {
  id: number;
  name: string;
  completed_amount: number;
  expected_amount: number;
  start_time: string;
  end_time: string;
  total_time: number;
  status: string;
  instructions: string;
  note: string;
  task_id: number;
  phase_pid: number;
  phase_id: number;
  workerName: string;
  sectorId: number;
};
export type Order = {
  id?: number;
  orderCode: string;
  companyName: string;
  items: OrderItem[];
  estimatedStart: string;
  estimatedEnd: string;
  realEnd: string;
  priority: string;
  active?: boolean;
  status?: string;
};

export type OrderFetch = {
  id?: number;
  orderCode: string;
  companyName: string;
  items: OrderItem[];
  dateCreated: string;
  estimatedStart: string;
  estimatedEnd: string;
  realEnd: string;
  priority: string;
  active?: boolean;
  status?: string;
};

type CompleteOrder = {
  order: Order;
  orderItems: OrderItem[];
};

export type PhaseTime = {
  id: number;
  totalTime: number;
};

export interface OrderItemsTabelProps {
  orderItems: OrderItem[];
  setOrderItems: any;
  orderItem?: OrderItem;
  orderToEdit?: Order;
}

export interface TaskFinishModalProps {
  isOpen: boolean;
  onClose: any;
  orderItemDTO: OrderItemPhaseDTO;
  shouldCallStartTask: boolean;
  handleGetPhasesProgress: any;
}

export interface ProductTableProps {
  products: Product[];
  setProducts: any;
  addedProducts: Product[];
  setAddedProducts: any;
  isAdded?: boolean;
  product?: Product;
  setOrderItems: any;
  orderItems: OrderItem[];
  fieldRef?: any;
  pageNumber: number;
  setPageNumber?: any;
}

export interface OrderTableProps {
  orders: Order[];
  order?: Order;
  setOrders: any;
  setOrderToEdit: any;
  setOrderItems: any;
  setOrder: any;
  fieldRef: any;
  pageNumber: number;
  setPageNumber: any;
  onOpen: any;
  isLoadingOrders: boolean;
  setOrdersLoading: any;
}

export type OrderWithProgress = {
  id: number;
  order_code: string;
  estimated_start: string;
  estimated_end: string;
  real_end: string;
  priority: string;
  total_tasks: number;
  new_tasks: number;
  in_progress_tasks: number;
  end_tasks: number;
  status?: string;
};

export interface PhasesTableProps {
  setIsOpenModal: any;
  isOpenModal: boolean;
  orderItem: OrderItemWithProgress;
  order: OrderWithProgress;
  phases: PhasesProgress[];
  handleGetPhasesProgress: any;
}

export const getProductsByFilter = async (filter: string, setProducts: any) => {
  try {
    //char like . was breaking page if it was passed, so we do check hear
    const regex = '[A-z0-9]';
    const firstChar = filter.charAt(0);
    const found = firstChar.match(regex);
    if (found == null) {
      alert('Nedozvoljen karakter na pocetku naziva proizvoda');
      return;
    }
    const products = await GET<Product[]>(
      SERVER_URL + '/api/products/' + filter
    );
    setProducts(products);
    console.log(products);
  } catch (error) {
    console.log('Error while fetching products.');
    console.error(error);
  }
};

export const createOrder = async (order: Order, setIsLoading: any) => {
  try {
    setIsLoading(true);
    //api will return missing resources if there is any missing resource
    const missingResouces = await POST<Resource[]>(
      SERVER_URL + '/api/order/save',
      order
    );

    setIsLoading(false);
    return missingResouces;
  } catch (error) {
    if (error instanceof Exception510) {
      const em = error as Error;
      console.log('Error while creating order. ' + em.message);
      alert(em.message);
      return undefined;
    } else if (error instanceof Error) {
      const em = error as Error;
      console.log('Error while creating order. ' + em.message);
      alert(em.message);
      return undefined;
    }
    setIsLoading(false);
    return undefined;
  }
};

export const updateOrderItem = async (
  orderItem: OrderItem,
  setIsLoading: any
) => {
  try {
    setIsLoading(true);
    const updateOrderItem = await POST<OrderItem>(
      SERVER_URL + '/api/order-item/update',
      orderItem
    );
    setIsLoading(false);
    return true;
  } catch (error) {
    console.log('Error while updating orderItem.');
    console.error(error);
    setIsLoading(false);
  }
};

export const getOrders = async (
  setOrders: any,
  pageNo: number,
  filter?: string,
  isActive?: boolean,
  orderStatus?: string
) => {
  try {
    let url = new URL(SERVER_URL + '/api/orders');

    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);
    if (isActive != undefined) params.append('isActive', isActive.toString());
    if (orderStatus !== undefined && orderStatus !== '')
      params.append('orderStatus', orderStatus);
    if (filter !== undefined && filter !== '') params.append('filter', filter);

    url.search = params.toString();
    const orders = await GET<Order[]>(url.toString());
    setOrders(orders);
  } catch (error) {
    console.log('Error while fetching products.');
    console.error(error);
  }
};

export const getOrdersWithProgressByFilter = async (
  filter: string,
  setOrders: any,
  selectedDates: Date[],
  status: string,
  sort: string,
  searchType: string,
  selectedDateRange: string,
  pageNo: number
) => {
  try {
    const regex = '[A-z0-9]';
    const firstChar = filter.charAt(0);
    const found = firstChar.match(regex);
    let url;

    url = new URL(SERVER_URL + '/api/order/report/progress');

    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);

    if (selectedDates.length > 1) {
      let firstDate = selectedDates[0];
      let secondDate = convertSecondSelectedDate(selectedDates[1]);

      params.append('dateFrom', firstDate.toISOString().substring(0, 10));
      params.append('dateTo', secondDate.toISOString().substring(0, 10));
    }
    if (status !== '') {
      params.append('status', status);
    }
    if (sort !== '') {
      params.append('sortBy', sort);
    }
    if (filter !== '') {
      params.append('filter', filter);
    }
    if (selectedDateRange !== '') {
      params.append('dateRangeBy', selectedDateRange);
    }

    params.append('searchType', searchType);

    url.search = params.toString();
    console.log(url.toString());
    const ordersWithProgress = await GET<OrderWithProgress[]>(url.toString());
    setOrders(ordersWithProgress);
    console.log(ordersWithProgress);
  } catch (error) {
    console.log('Error while fetching products.');
    console.error(error);
  }
};

export const deleteOrder = async (order: Order, setOrders: any) => {
  try {
    const deletedOrder = await POST<Order>(
      SERVER_URL + '/api/order/delete',
      order
    );
    setOrders([]);
    return true;
  } catch (error) {
    const em = error as Error;
    console.log('Error while deleting order. ' + em.message);
    alert('Error while deleting order. ' + em.message);
    return false;
  }
};

export const updateOrderStatus = async (order: Order, action: string) => {
  try {
    let url = new URL(SERVER_URL + UPDATE_ORDER_STATUS + action);

    const missingResouces = await POST<Resource[]>(url.toString(), order);
    return missingResouces;
  } catch (error) {
    const em = error as Error;
    console.log('Error while updating order status. ' + em.message);
    alert('Error while updating order status. ' + em.message);
    return undefined;
  }
};

export const deleteOrderItem = async (orderItem: OrderItem) => {
  try {
    const deletedOrderItem = await POST<OrderItem>(
      SERVER_URL + '/api/order-item/delete',
      orderItem
    );
    return true;
  } catch (error) {
    const em = error as Error;
    console.log('Error while deleting order item. ' + em.message);
    alert('Error while deleting order item. ' + em.message);
    return false;
  }
};

export const getOrdersWithProgress = async (setOrders: any, pageNo: number) => {
  try {
    const url = new URL(SERVER_URL + '/api/order/report/progress');
    let params = new URLSearchParams();
    params = getPaginationParams(params, pageNo);
    url.search = params.toString();
    console.log(url.toString());
    const orders = await GET<OrderWithProgress[]>(url.toString());
    setOrders(orders);
    console.log(orders);
  } catch (error) {
    console.log('Error while fetching orders.');
    console.error(error);
  }
};

export const getOrderItemsWithProgress = async (
  setOrderItemsWithProgress: any,
  orderId: number
) => {
  try {
    const ordersItems = await GET<OrderItemWithProgress[]>(
      SERVER_URL + '/api/order-item/report/progress/' + orderId
    );
    setOrderItemsWithProgress(ordersItems);
    console.log(ordersItems);
  } catch (error) {
    console.log('Error while fetching orders.');
    console.error(error);
  }
};

export const getOrderItems = async (orderId: number) => {
  try {
    const orderItems = await GET<OrderItem[]>(
      SERVER_URL + GET_ORDER_ITEMS + orderId
    );
    return orderItems;
  } catch (error) {
    if (error instanceof NoContentException) {
      return [];
    }
    console.log('Error while fetching orders.');
    console.error(error);
  }
};

export const getPhasesProgress = async (
  setPhasesProgress: any,
  PhaseId: number
) => {
  try {
    const phasesProgress = await GET<PhasesProgress[]>(
      SERVER_URL + '/api/product/phase/report/progress/' + PhaseId
    );
    setPhasesProgress(phasesProgress);
    console.log(phasesProgress);
  } catch (error) {
    console.log('Error while fetching orders.');
    console.error(error);
  }
};

export function convertPriority(value: string | undefined) {
  let priority = '';
  if (value === undefined) return priority;
  if (value === 'LOW') {
    priority = 'Nizak';
  } else if (value === 'MEDIUM') {
    priority = 'Srednji';
  } else if (value === 'HIGH') {
    priority = 'Visok';
  }
  return priority;
}

export function convertDate(value: string | undefined) {
  let formattedDate;
  if (value) {
    const date = new Date(value);
    formattedDate = date
      .toLocaleString('en-GB', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
      })
      .replace(/\//g, '-');
  } else {
    formattedDate = '';
  }
  return formattedDate;
}

export function convertToTimestamp(value: string | undefined) {
  let formattedDateTime;
  if (value) {
    const date = new Date(value);

    formattedDateTime = date
      .toLocaleString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/\//g, '-');
  } else {
    formattedDateTime = '';
  }
  return formattedDateTime;
}

export function createFormatedDate(value: number) {
  const minutes = Math.floor(value / 60) % 60;
  const hours = Math.floor(value / 3600) % 24;
  const days = Math.floor(value / 86400);
  return `  ${days} d ${hours} h ${minutes} m`;
}

export function convertSecondSelectedDate(value: Date) {
  let secondDate = new Date();
  value.getDate() === 31
    ? secondDate.setDate(1)
    : secondDate.setDate(value.getDate() + 1);

  // Need condition because if secondDate is last of the month
  value.getDate() === 31
    ? secondDate.setMonth(value.getMonth() + 1)
    : secondDate.setMonth(value.getMonth());

  // Need condition for 31 dec. months counts from 0 (December is 11)
  value.getDate() === 31 && value.getMonth() == 11
    ? secondDate.setFullYear(value.getFullYear() + 1)
    : secondDate.setFullYear(value.getFullYear());

  return secondDate;
}

export type WorkerBySectorDTO = {
  userId: number;
  workerName: string;
  workerUsername: string;
};
export const getWorkersBySector = async (sectorId: number) => {
  const url = new URL(SERVER_URL + '/worker/users/' + sectorId);
  return await GET<WorkerBySectorDTO[]>(url.toString());
};

export const assignWorker = async (itemPhase: OrderItemPhaseDTO) =>
  await POST<boolean>(SERVER_URL + '/worker/task/change-assignment', itemPhase);

export type TaskAssignmentDTO = {
  assignmentStart: string;
  assignmentEnd: string;
  assignedBy: WorkerBySectorDTO;
  worker: WorkerBySectorDTO;
};
export const getTaskAssignments = async (taskId: number) =>
  await GET<TaskAssignmentDTO[]>(
    SERVER_URL + '/api/task/assignments/' + taskId
  );

export const updateOrderStatusToManuallyComplete = async (orderId: number) => {
  try {
    const updateOrderStatus = await POST<number>(
      SERVER_URL + '/api/order/manually-complete',
      orderId
    );
    return true;
  } catch (error) {
    console.log('Error while updating order status to MANUALLY COMPLETE.');
    console.error(error);
    return false;
  }
};

export function convertOrderStatus(value: string | undefined) {
  let status = '';
  if (value === undefined) return status;
  if (value === 'IN_PROGRESS') status = 'U izradi';
  if (value === 'COMPLETE' || value === 'MANUALLY COMPLETE')
    status = 'Završeno';
  return status;
}
